/* iframe videos
-------------------------------------------------- */

.vimeo-iframe,
.youtube-iframe,
.other-iframe {
  padding-bottom: 56.67%;
  position: relative;
  width: 100%;
}

.video-iframe {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100% !important;
}

// ==========[ WYSIWYG / PAGE BUILDER ]==========

.full-left-image,
.full-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-left-image {
  right: 50%;
  left: 0;
}

.full-right-image {
  right: 0;
  left: 50%;
}

// ==========[ IMAGE BLOCKS ]==========

.image-block {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.square {
    padding-top: 100%;
  }

  &.long {
    padding-top: 133%;
  }

  &--half {
    padding-top: 56%;
  }

  &.contain {
    background-size: contain;
  }
}

.blocks-animals {
  position: absolute;
  z-index: 10;

  img {
    width: 120px;
  }

  &__right {
    &-top {
      right: 30px;
      top: -50px;
    }

    &-bottom {
      right: 0;
      bottom: 0;
    }
  }

  &__left {
    &-top {
      left: 0;
      top: -50px;
    }

    &-bottom {
      left: 0;
      bottom: 0;
    }
  }
}



// ==========[ SLIDESHOW DOTNAV ]==========
.uk-dotnav {
  >*>* {
    background: transparent;
    border: 1px solid $primary;
    transition: .3s;
  }

  >*> :hover {
    background-color: $primary;
  }

  >.uk-active {
    &>* {
      background-color: $primary;
      border: 1px solid $white;
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background: transparent;
        border: 1px solid $primary;
        display: flex;
        border-radius: 15px;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
      }
    }
  }
}


// ==========[ REVIEW SLIDER ]==========
.review {
  &-container {}

  &-image {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    margin-bottom: 20px;
  }

  &-text {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    h2 {
      width: 80%;
      margin-bottom: 0;
    }
  }

  &-person {
    * {
      color: $primary;
    }

    &__name {
      text-transform: uppercase;
    }

    &__function {}
  }
}

// ==========[ BRAND SLIDER ]==========
.brand {
  &-slider {}

  &-container {
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
  }
}



/* Modals
-------------------------------------------------- */

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($color: #f6d4a2, $amount: 0.05);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow-y: scroll;

  &--big {
    align-items: flex-start;
  }

  &__box {
    width: 100%;
    max-width: 1050px;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
    padding: 45px 60px;

    &--image {
      position: relative;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 66.66%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__content {
    padding-left: calc(33.33% + 50px);
  }
}

/* Category blocks
-------------------------------------------------- */
.block-link-category-wrapper {
  text-align: center;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: $black;
  }

  &:hover {
    text-decoration: none;

    span {
      color: $primary;
      text-decoration: none !important;
    }

    .block-link-category {
      background-color: $primary;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }

    .block-image-link-category {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }
  }
}

.block-link-category {
  position: relative;
  background-color: $secondary;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  span {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);

    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: $black;
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: $primary;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    span {
      color: $white;
    }
  }
}

.block-image-link-category {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
  }

  span {
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 25px;

    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: $white;
    transition: color 0.3s ease;
  }

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    .overlay {
      background-color: transparentize($color: $primary, $amount: 0.3);
    }
  }
}

// ==========[ ARTICLE ]==========
.article-share {
  border-top: 1px solid $lines;
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 70px;

  p,
  a {
    color: $muted;
    text-transform: uppercase;
  }

  .uk-button-secondary {
    padding-top: 0;
  }

  .share {
    &-left {
      padding-left: 10px;
      padding-right: 0;
      padding-top: 0;
      color: $muted;
      display: flex;
      align-items: center;
      padding-bottom: 0;
      margin-right: 15px;


      span {
        i {
          transform: rotate(180deg);
        }

        margin-left: 0px;
        margin-right: 10px;
      }

      &:hover {
        padding-right: 0;
        color: $primary;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      color: $primary;

      i {
        margin-left: 10px;
      }

      &:hover {
        color: darken($color: $primary, $amount: 15%);
      }
    }

  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  // ==========[ WYSIWYG / PAGE BUILDER ]==========

  .full-left-image,
  .full-right-image {
    position: relative;
    padding-bottom: 66.6%;
  }

  .full-left-image {
    right: 0%;
    left: 0;
  }

  .full-right-image {
    right: 0;
    left: 0%;
  }

  .blocks-animals {
    img {
      width: 75px;
    }
  }
}

// Mobile devices
@media (max-width: 640px) {

  // ==========[ SLIDER ARROW NAV ]==========
  .uk-slidenav-previous,
  .uk-slidenav-next {
    width: 27px;
  }

  // ==========[ REVIEW SLIDER ]==========
  .review {
    &-image {
      height: 60px;
      width: 60px;
      border-radius: 50px;
      margin-bottom: 20px;
    }

    &-text {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;

      h2 {
        font-size: 18px;
        line-height: 21.6px;
        width: 90%;
      }
    }

    &-person {
      * {
        color: $primary;
      }

      flex-direction: column;
      align-items: center;

      &__name {
        font-size: 14px;
        line-height: 21px;
      }

      &__function {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
      }
    }
  }

  .blocks-animals {
    img {
      width: 50px;
    }
  }
}