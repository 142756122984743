/* General
-------------------------------------------------- */

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body.muted {
  background-color: $muted;
}

.body-bg {
  width: 100vw;
  height: 100vh;
  z-index: -9999999;
  background-image: url('/dist/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999;
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &--contain {
    background-size: contain;
  }
}

header {
  background-color: $white;
}

.fit-content {
  width: fit-content;
}

.uk-container {
  max-width: 1140px;
}

.coloredtext {
  color: $primary;
}

.colored {
  background-color: $primary;

  h2,
  .uk-h1,
  p,
  h3 {
    color: $white;
  }

  .uk-button {
    &-primary {
      background-color: $white;
      color: $primary;
      border: 1px solid $white;

      &:hover {
        color: $white;
        border: 1px solid $white;
      }
    }

    &-tertiary {
      color: $white;

      &:hover {
        color: darken($color: $white, $amount: 8%);
      }
    }
  }
}

.colormuted {
  background-color: $primary-muted;

  h2 {
    color: $primary;
  }

  .uk-slidenav {
    color: $primary;
  }
}

.uk-section {

  &-muted {
    background-color: $background;
  }

  &-white {
    background-color: $white;

  }
}

.muted {
  background-color: $background;
}


.flavour-image {
  height: 100px;
  width: 100px;
  background-color: transparent;
  border: 1px solid $border;

  &.uk-active {
    border: 2px solid $primary;
  }
}

.table-border {
  tr {
    border-bottom: 1px solid $lines;

  }
}



// ==========[ LAZYSIZES ]==========

// lazysizes
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

// TER INFO: bijbehorende HTML:
// Voor img tag:
// <div class="lazyload-wrapper" style="padding-bottom: <?= getImageRatio($page->image) ?>%;">
//   <img data-src="<?= $page->image->size(1600, 1600, ['cropping' => false])->url ?>" alt="<?= $page->image->description ?>" class="lazyload">
// </div>
// Voor backgroundimage:
// <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div>

.contact {
  &-container {
    z-index: 5;

    img {
      width: 50%;
    }
  }
}


#map {
  height: 600px;
  // margin-top: -100px;
  z-index: 0;
}

// ==========[ VERY LARGE DESKTOPS ]==========

@media (max-width: 1600px) {}

// ==========[ DESKTOPS ]==========

@media (max-width: 1200px) {}

// ==========[ TABLETS ]==========

@media (max-width: 960px) {
  .uk-section-large {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .nopadding {
    padding-top: 0;
  }
}

// ==========[ MOBILE DEVICES ]==========

@media (max-width: 640px) {}