.product-gallery {
  border: 1px solid $border;

  &__image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $white;
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    gap: 10px 10px;
    grid-template-areas: '. . . . .';
    margin-top: 30px;

    .product-gallery__nav--image {
      background-color: $white;
      border: 1px solid $border;
      padding: 10px 15px;
      height: 100px;
      cursor: pointer;
      transition: border 0.15s ease-in-out;

      >img {
        max-height: 100%;
      }

      &:hover {
        border: 3px solid $primary;
      }

      &.uk-active {
        border: 1px solid $primary;
        border-radius: 0 !important;
      }
    }

    .product-gallery__nav--plus {
      border: 0;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 21px;
      cursor: pointer;

      &:hover {
        background-color: darken($color: $primary, $amount: 7%);
      }
    }
  }
}



// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  .product-gallery {
    &__nav {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px 10px;
      grid-template-areas: '. . .' '. . .';
    }
  }
}

// MS IE
@media all and (-ms-high-contrast: none) {
  .product-gallery {
    &__nav {
      display: -ms-grid;
      -ms-grid-columns: repeat(5, 1fr);
      -ms-grid-rows: 1fr;
    }
  }
}