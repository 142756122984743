.uk-link {
    position: relative;
    padding-right: 20px;

    span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $primary;
        transition: color 0.3s ease;
    }

    i,
    svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.4s ease, color 0.3s ease;
        color: $primary;
        font-size: 14px;
    }

    &:hover {
        text-decoration: none;

        span,
        i,
        svg {
            color: darken($color: $primary, $amount: 6%);
        }

        i,
        svg {
            transform: translateX(6px) translateY(-50%);
        }
    }

    &--black {

        span,
        i,
        svg {
            color: $black;
        }
    }
}

.uk-button {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    padding: 14px 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica LT Std', sans-serif;
    transition: .2s;
    display: flex;
    align-items: start;

    i {
        margin-top: -2px;
    }
}

.uk-button-primary {
    background-color: $primary;
    color: $white;
    padding: 19px 29px 13px 29px;
    border: 1px solid $primary;

    &:hover {
        background-color: transparent;
        color: $primary;
    }
}

.uk-button-secondary {
    background-color: transparent;
    border: 1px solid $primary;
    padding: 19px 29px 13px 29px;

    color: $primary;

    &:hover {
        background-color: $primary;
        color: $white;
        border: 1px solid transparent;
    }
}

.uk-button-tertiary {
    background-color: transparent;
    padding: 19px 0px 13px 0;
    color: $primary;

    &:hover {
        color: darken($color: $primary, $amount: 20%);
    }
}

.button-icon {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    color: $white;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &--large {
        width: 120px;
        height: 120px;
        font-size: 21px;
    }

    &:hover {
        background-color: $secondary;
        color: $primary;
        box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
    }
}

.block-link {
    position: relative;
    background-color: $secondary;
    display: block;
    width: 100%;
    padding-top: 75%;
    border-radius: $border-radius;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    span {
        position: absolute;
        left: 25px;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);

        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $black;
        transition: color 0.3s ease;
    }

    &:hover {
        background-color: $primary;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        span {
            color: $white;
        }
    }
}

.block-image-link {
    position: relative;
    background-color: $secondary;
    display: block;
    width: 100%;
    padding-top: 75%;
    border-radius: $border-radius;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparentize($color: $black, $amount: 0.7);
        transition: background-color 0.3s ease;
    }

    span {
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;

        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $white;
        transition: color 0.3s ease;
    }

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        .overlay {
            background-color: transparentize($color: $primary, $amount: 0.3);
        }
    }
}

.button-totop {
    background-color: $secondary;
    color:$primary;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 555;
    padding: 15px 20px;
    i {
        margin: 0;
    }
    &:hover {
        padding: 15px 20px;
        background-color: darken($color: $secondary, $amount: 10%);
        i {
            margin: 0;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
    .button-totop {
        
        position: fixed;
        right: 25px;
        bottom: 25px;
        z-index: 555;
        padding: 15px 20px;
        i {
            margin: 0;
        }
        &:hover {
            padding: 15px 20px;
            i {
                margin: 0;
            }
        }
    }
}

// Mobile devices
@media (max-width: 640px) {

    .uk-button-primary,
    .uk-button-secondary {
        padding: 15px 17px 9px 17px;
    }

    .uk-button-tertiary {
        font-size: 14px;
    }

    .uk-button {
        font-size: 14px;

        i {
            margin-top: 0;
        }
    }
}