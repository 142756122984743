.products-header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    border-bottom  : 1px solid $border;

    .custom-select {
        margin-bottom: -1px;
        border-radius: $border-radius $border-radius 0 0;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .products-header {
        flex-flow: column;
        align-items: stretch;
        border-bottom: 0;
    }
}

// Mobile devices
@media (max-width: 640px) {
}