.product-navbar {
  background-color: $primary;
  padding: 15px 0;
  z-index: 10;

  .uk-container {
    width: 100%;
  }

  .uk-navbar-left {
    position: relative;

    .navbar-cat {
      position: absolute;
      right: -15vw;
      bottom: -90px;
      transform: translate(100%, 0%);
      pointer-events: none;
    }
  }

  .uk-navbar-nav {
    >li {
      >a {
        margin: 0;
        margin-right: 32px;
        padding: 0;
        min-height: 0;

        font-size: 18px;
        line-height: 27px;
        color: $white;
        text-transform: uppercase;
        font-family: $helvetica;

        &:hover {
          position: relative;

          &::after {
            content: attr(title);
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            font-weight: 800;
            background-color: $primary;

          }
        }

        &.uk-active {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translate(-50%, 100%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $primary;

          }
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }

  .uk-navbar-right {
    a {
      color: $white;
      margin-right: 20px;
      display: flex;

      &.cart-button {
        margin-right: 0;
      }

      &:hover {
        text-decoration: none;
      }
    }

    svg {
      height: 18px;
    }

    i {
      font-size: 18px;
    }
  }

  .uk-navbar-dropdown {
    background-color: $white;
    border: 1px solid $border;
    border-radius: 0 0 $border-radius $border-radius;
    padding: 22px 30px 30px;

    .uk-nav-divider {
      margin: 25px 0;
    }

    .uk-nav-sub-header {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 12px;
      color: $text;
    }

    .uk-nav {
      li {
        a {
          font-weight: 300;
          font-size: 16px;
          line-height: 28px;
          color: $black;
          padding: 0;

          &:hover {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
    }

    .uk-nav-header {
      a {
        font-weight: bold !important;
        font-size: 18px !important;
        line-height: 27px !important;
        color: $black !important;
        text-transform: none !important;

        &:hover {
          color: $primary !important;
          text-decoration: none !important;
        }
      }

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}