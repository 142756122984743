.size-picker {
    &--title {
        font-size: 14px;
        font-family: $helvetica;
        text-transform: uppercase;
    }
    .custom-select {
        border: 1px solid $lines;
        font-family: $helvetica;
        &:focus {
            background-color: transparent;
        }
    }

    .sizes {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;

        button.size {
            height: 30px;
            border: 1px solid $border;
            margin: 0px 5px 5px 0;
            border-radius: $border-radius;
            background-color: transparent;
            cursor: pointer;

            font-family: $ff-stack;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            color: $black;

            transition: 0.15s ease-in-out;

            &:hover,
            &.uk-active {
                background-color: $secondary;
                border: 1px solid $primary;
                font-weight: bold;
            }

            &.uk-active {
                font-weight: bold;
            }
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}