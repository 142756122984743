.search-bar {
  width: 100%;
  position: relative;

  &__search {
    position: relative;
    width: 100%;

    input[type='text'],
    input[type='search'] {
      width: 100%;
      height: 66px;
      padding: 0 0px 0 50px;
      border-radius: 3px;
      border: none;
      text-align: end;

      // font-family: $ff-stack;
      font-size: 18px;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &:focus {
        color: $primary;
        // border : 1px solid $primary;
        outline: none;
      }
    }

    button[type='submit'] {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-55%);
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: 0;
      font-size: 16px;
      color: $black;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }

  &__search-results {
    position: absolute;
    top: 54px;
    background-color: $white;
    border: 1px solid $border;
    width: 100%;
    border-radius: 0 0 4px 4px;
    padding: 30px;
    margin: 0;
    list-style-type: none;
    transform: translateY(15px);
    opacity: 0;
    z-index: -9999;

    .loader {
      display: flex;
      justify-content: center;
      padding: 20px;
      font-size: 25px;
      color: $primary;
    }

    transition: transform 0.4s ease,
    opacity 0.2s ease;

    li {
      a {
        display: block;
        margin-left: -30px;
        margin-right: -30px;
        padding: 3px 30px;

        font-weight: 300;
        font-size: 16px;
        color: $black;

        mark {
          font-weight: bold;
          background: transparent;
        }

        &:hover {
          background-color: $secondary;
          text-decoration: none;
        }
      }

      &.title {
        p {
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 0.1em;
          line-height: 12px;
          color: $grey-dark;
          text-transform: uppercase;
          margin-top: 25px;
          margin-bottom: 15px;
        }
      }

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.active {
      transform: translateY(0);
      opacity: 1;
      z-index: 999;
    }
  }

  &-mobile {
    width: 100%;

    &__search {
      position: relative;
      width: 95%;

      input[type='text'],
      input[type='search'] {
        width: 100%;
        height: 50px;
        padding: 0 0px 0 50px;
        border-radius: 3px;
        border: none;
        background-color: transparent;
        padding-top: 6px;

        // font-family: $ff-stack;
        font-size: 16px;
        color: $black;

        &::placeholder {
          color: $black;
        }

        &:focus {
          color: $primary;
          // border : 1px solid $primary;
          outline: none;
        }
      }

      button[type='submit'] {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        background-color: transparent;
        border: 0;
        font-size: 16px;
        color: $black;
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }
    }

    &__search-results {
      position: absolute;
      left: 0;
      top: 50px;
      background-color: $white;
      border: 1px solid $border;
      width: 100%;
      border-radius: 0 0 4px 4px;
      padding: 30px;
      margin: 0;
      list-style-type: none;
      transform: translateY(15px);
      opacity: 0;
      z-index: -9999;

      .loader {
        display: flex;
        justify-content: center;
        padding: 20px;
        font-size: 25px;
        color: $primary;
      }

      transition: transform 0.4s ease,
      opacity 0.2s ease;

      li {
        a {
          display: block;
          margin-left: -30px;
          margin-right: -30px;
          padding: 3px 30px;

          font-weight: 300;
          font-size: 16px;
          color: $black;

          mark {
            font-weight: bold;
            background: transparent;
          }

          &:hover {
            background-color: $secondary;
            text-decoration: none;
          }
        }

        &.title {
          p {
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.1em;
            line-height: 12px;
            color: $grey-dark;
            text-transform: uppercase;
            margin-top: 25px;
            margin-bottom: 15px;
          }
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &.active {
        transform: translateY(0);
        opacity: 1;
        z-index: 999;
      }
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}