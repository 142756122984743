.cart {
    overflow: hidden;

    &__container {
        border-bottom: 1px solid $border;

        &:first-child {
            border-top: 1px solid $border;
        }
    }

    &-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .uk-input {
            border: 1px solid $lines;

            &:active,
            &:focus {
                background-color: transparent;
                border: 1px solid $primary;

            }
        }

        &-button {
            width: 65%;
        }

        &-form {
            width: 20%;

            .uk-input {
                height: 52px;
            }
        }
    }

    &-labels {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;

    }

    &__row {
        padding: 15px 0;
        display: grid;
        grid-template-columns: 50px 3fr .5fr .5fr;
        grid-template-areas: "remove link amount price";
        column-gap: 10px;
        row-gap: 10px;

        &:last-of-type {
            border-bottom: 0;
        }

        // Cart gird area
        .cga-remove {
            grid-area: remove;

            // positioning content container
            display: flex;
            justify-content: end;
            align-items: center;
        }

        .cga-link {
            grid-area: link;
        }

        .cga-amount {
            grid-area: amount;

            // positioning content container
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .cga-price {
            grid-area: price;

            // positioning content container
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
    }

    &__remove {
        border: 0;
        background-color: transparent;
        padding: 0;
        font-size: 21px;
        color: $muted;
        cursor: pointer;

        &:hover {
            color: $danger-color;
        }
    }

    &__image {
        position: relative;
        display: flex;
        justify-content: center;
        max-width: 150px;

        img {
            width: 60%;
        }
    }

    &__product-info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1,
        .uk-h1,
        h2,
        .uk-h2,
        h3,
        .uk-h3,
        h4,
        .uk-h4,
        h5,
        .uk-h5,
        p,
        .cms,
        .text {
            transition: all .3s ease;
            color: $text;
        }
    }

    &__link {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;

            .cart__product-info {

                h1,
                .uk-h1,
                h2,
                .uk-h2,
                h3,
                .uk-h3,
                h4,
                .uk-h4,
                h5,
                .uk-h5,
                p,
                .cms,
                .text {
                    color: $primary;
                }
            }
        }

        &.no-hover {

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;

                .cart__product-info {

                    h1,
                    .uk-h1,
                    h2,
                    .uk-h2,
                    h3,
                    .uk-h3,
                    h4,
                    .uk-h4,
                    h5,
                    .uk-h5,
                    p,
                    .cms,
                    .text {
                        color: $text;
                    }
                }
            }
        }
    }

    &__amount {
        height: 50px;
        width: 65px;
        border: 1px solid $border;
        color: $black;
        border-radius: 3px;

        font-family: $futura;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $black;

        &--danger {
            border: 1px solid $danger-color;
            color: $danger-color;
        }

        &:focus {
            border: 1px solid $primary;
            outline: none;
        }
    }

    &__prices {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;

        &--current {
            margin: 0 10px 0 0;
            font-family: $helvetica;
            font-size: 18px;
            line-height: 27px;
            color: $primary;
        }

        &--label {
            margin: 0 10px 0 0;
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            color: $text;
        }

        &--old {
            margin: 0 10px 0 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: $text;
            text-decoration: line-through;
        }
    }

    &__footer {
        padding: 60px 0;

        select {
            width: 270px;
        }
    }

    &__totals {
        display: grid;
        grid-template-columns: 3.2fr 1.3fr;
        gap: 12px 30px;

        &__label {
            text-align: right;
            margin: 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: $black;

        }

        &__price {
            text-align: right;
            font-family: $helvetica;
            font-size: 18px;
            line-height: 27px;
            color: $primary;
            margin: 0;
            white-space: nowrap;

            &.delivery-cost {
                color: $success-color;
            }

            &.total {
                font-size: 18px;
                line-height: 27px;
                color: $primary;
            }
        }

        &-total {
            border-top: 1px solid $grey;
            margin-top: 40px;
            padding-top: 40px;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {
    .cart {
        &__totals {
            grid-template-columns: 2.5fr 2fr;
        }

        &-container-button {
            width: auto;
        }
    }
}

// Tablets
@media (max-width: 960px) {
    .cart {
        &__row {
            grid-template-columns: 40px 3fr 1fr 1fr;
        }

        &__totals {
            grid-template-columns: 1fr 1fr;

            h3 {
                padding-right: 10px;
            }
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
    .cart {
        &__row {
            grid-template-columns: 40px 1fr;
            grid-template-areas:
                "remove link link"
                "amount amount price";
            row-gap: 20px;
        }

        &__footer {
            padding: 20px 0;
        }

        &__totals {
            grid-template-columns: 1.5fr 1fr;
            gap: 5px 30px;

            &-total {
                margin-top: 20px;
                padding-top: 20px;
            }

            &__label {
                font-size: 14px;
            }

            &__price {
                font-size: 16px;
            }

            h3 {
                padding-right: 0px;
            }
        }
    }
}