.top-banner {
  background-color: $background;
  padding: 30px 0;

  &--title {
    color: $black;
  }

  &--text {
    color: $black;

    p {
      color: $black;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &--close {
    text-transform: uppercase;
    color: $primary;

    i {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}