.home-background {
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__title.display {
    color: $white;
    width: 65%;
    z-index: 10;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: .3;
    z-index: 2;
  }

  .uk-container {
    width: 100%;
    height: 100%;

  }

  &__circle-text {
    &__container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    position: absolute;
    right: 0;
    bottom: -40px;
    width: 200px;
    height: 200px;
    background-color: $primary;
    border-radius: 200px;
    z-index: 5;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    h2 {
      color: $white;
      text-align: center;
      margin-bottom: 0;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  .home-background {
    height: 240px;

    &__title.display {
      color: $white;
      width: 90%;
      z-index: 10;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &__circle-text {
      &__container {
        position: relative;
        width: 100%;
        height: 100%;
      }

      position: absolute;
      right: 0;
      bottom: -40px;
      width: 100px;
      height: 100px;
      background-color: $primary;
      border-radius: 100px;
      z-index: 5;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      h2 {
        color: $white;
        text-align: center;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
}