footer {
  background-color: $primary;
  padding: 100px 0px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;


    li {
      a {
        font-weight: 300;
        font-size: 14px;
        line-height: 16.8px;
        color: $white;
        text-transform: uppercase;
      }
    }
  }

  .socials {
    i {
      font-size: 16px;
    }

    &.double-columns {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
    }
  }

  .notes {
    a {
      font-size: 16px !important;
    }
  }

  .double-columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  h3,
  i,
  p,
  a {
    color: $white;

    &.small {
      font-size: 14px;
    }
  }

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }

  a {

    &:hover {
      text-decoration: none;
      color: $white;
      font-weight: bolder !important;
    }
  }

  .sub-footer {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: $white;
    }

    i {
      margin-bottom: 5px;
    }
  }
}

.footer {
  &-grass {
    height: 250px;
    // background-size: contain;
  }

  &-dog {
    position: absolute;
    top: 0px;
    left: -50px;
    transform: translateY(-100%);
    z-index: 2;
  }

  &-logo {
    position: relative;
    z-index: 5;
  }
  &-contactdata {
    i {
      margin-top: 2px;
    }
  }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {
  .footer {
    &-contactdata {
      flex-direction: column;
    }
  }
}

// Tablets
@media (max-width: 960px) {
  .footer {
    &-contactdata {
      flex-direction: row;
    }

    &-grass {
      height: 150px;
    }

    &-dog {
      height: 200px;
      left: 0;

      img {
        height: 100%;
      }
    }
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .sub-footer {
    flex-direction: column;
    align-items: start !important;
  }
}

// Mobile devices
@media (max-width: 640px) {
  footer {
    padding: 50px 0;

    .sub-footer {
      margin-top: 20px;
      flex-flow: column;
      align-items: flex-start;

      a {
        font-size: 12px;
        line-height: 18px;
      }

      i {
        margin-bottom: 2px;
      }
    }

    .socials {
      margin-top: 30px;
    }

    h3 {
      font-size: 24px;
      line-height: 28.8px;
    }

  }
}