.promotion {
    border: 1px solid $lines;
    padding: 40px;
    height: 100%;
    align-items: center;

    &__type {
        top: 10px;
        left: 10px;
        height: 125px;
        width: 125px;
        background-color: $primary;
        color: $white;
        font-size: 16px;
        line-height: 21px;
        border-radius: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        text-align: center;
        padding: 5px;
        font-weight: bold;
        position: absolute;
    }

    &__price {
        &-current {
            color: $primary;
            font-size: 24px;
            font-family: $helvetica;
            display: flex;
            align-items: end;
            line-height: 24px;
        }

        &-old {
            color: $black;
            font-size: 16px;
            font-family: $helvetica;
            text-decoration: line-through;
            display: flex;
            align-items: end;
            line-height: 24px;
        }
    }

    &__quote {
        background-color: $primary;
        padding: 75px 194px;
        align-items: center;

        h2,
        h3 {
            color: $white;
        }

        &-small {
            padding: 70px 60px;
            height: 100%;
        }
    }

    &__image {
        height: fit-content;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
    .promotion__type {
        height: 75px;
        width: 75px;
        font-size: 12px;
    }
}