.product {
  display: flex;
  flex-flow: column;
  // justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  &__header {
    width: 100%;
    position: relative;

    &.background {
      // background-size: contain;
      padding-bottom: 100%;
    }
  }

  &__image {}

  &__like {
    // position: absolute;
    top: 20px;
    right: 20px;
    color: $primary;
    cursor: pointer;
    background-color: $white;
    border-radius: 3px;
    border: 0;
    padding: 6px;
    margin-left: 10px;

    >.normal {
      display: block;
    }

    >.active {
      display: none;
    }

    &:hover,
    &--liked {
      >.normal {
        display: none;
      }

      >.active {
        display: block;
      }
    }
  }

  &__info {
    margin-top: 15px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 18px;
    line-height: 27px;
    color: $black;
    transition: .2s;
    text-transform: uppercase;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__price {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    &--label {
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      color: $black;
      margin: 0 10px 0 0;
    }

    &--current {
      font-size: 32px;
      line-height: 38.4px;
      color: $primary;
      margin: 0 10px 0 0;
      font-family: $helvetica;

      &-card {
        font-size: 18px;
        line-height: 27px;
      }
    }

    &--old {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      text-decoration: line-through;
      font-family: $helvetica;
      margin: 0;
    }
  }

  &__cart {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    background-color: $primary;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    margin-left: 20px;
    border-radius: 3px;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: .2s;
    cursor: pointer;

    i {
      font-size: 18px;
    }

    &:hover {
      background-color: darken($color: $primary, $amount: 4%);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    .product__title {
      color: $primary;
    }

    .product__cart {
      opacity: 1;
    }
  }
}

.product__stock {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon {
    color: $white;
    font-size: 14px;
    margin-right: 10px;
  }

  p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  &--success {
    .icon {
      color: $success-color;
    }

    p {
      color: $success-color;
    }
  }

  &--warning {
    .icon {
      color: $warning-color;
    }

    p {
      color: $warning-color;
    }
  }

  &--error {
    .icon {
      color: $danger-color;
    }

    p {
      color: $danger-color;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {
  .product__like {
    margin-left: 0;
    padding-left: 0;
  }
}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  .product {
    &__title {
      font-size: 14px;
      line-height: 21px;
    }

    &__price {
      &--current {
        font-size: 18px;
        line-height: 27px;
      }
    }

    &__info {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }

  .uk-grid-mobile {
    margin-left: -30px !important;

    li {
      padding-left: 30px !important;
    }
  }
}