.blog-filter {
    width: 100%;

    p {
        text-transform: uppercase;
        margin-bottom: -5px !important;
    }

    .uk-first-column {
        padding-left: 0;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}