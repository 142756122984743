.newsletter {
  form {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin: 0px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;
    }
  }

  .input-errors {
    color: $danger-color;

    p {
      color: $danger-color;
    }
  }

  .input-success {
    color: $success-color;

    p {
      color: $success-color;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}