.strengths {
    margin-top: 40px;

    .strength {
        margin-bottom: 24px;

        &:nth-last-child(-n+3) {
            margin-bottom: 0px;

        }

        &-cat {
            padding-bottom: 66.75%;


            &::after {
                position: absolute;
                content: "";
                background-color: $black;
                height: 100%;
                width: 100%;
                opacity: .2;
            }

            .uk-button {
                z-index: 5;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: max-content;

                &:hover {
                    background-color: $white;
                    border: 1px solid $white;
                }
            }
        }
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
    .strengths {
        margin-top: 0px;
    }
}