.main-navbar {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 20px 20px;
  padding: 25px 0;
  align-content: center;

  &__left {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    font-size: 16px;

    a {
      color: $black;
      margin-right: 10px;
      &.uk-active {
        color: $primary;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .uk-logo {
      display: block;
    }

    &-top {
      color: $muted;
      margin-bottom: 20px;
      font-size: 16px;

      i {
        font-size: 14px;
        margin-right: 5px;
        margin-top: -5px;
        color: $muted;
        opacity: .8;
      }
    }


  }

  &__center {
    display: flex;
    align-items: center;

    img {
      max-width: 180px;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &-top {
      color: $muted;
      // margin-bottom: 20px;
      font-size: 16px;

      i {
        font-size: 14px;
        margin-right: 5px;
        margin-top: -5px;
        color: $muted;
        opacity: .8;
      }
    }

    >a,
    >.dropdown-item>a {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      color: $black;
      margin-right: 30px;

      span {
        margin-top: 10px;
        font-weight: normal;
        font-size: 16px;
      }

      &:hover {
        color: $primary;
        text-decoration: none;
      }

      &:last-child {
        margin-right: 0;
      }

      &.cart-button {
        position: relative;

        .amount {
          position: absolute;
          top: -7px;
          left: calc(50% + 3px);
          width: 18px;
          height: 18px;
          background-color: $primary;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          margin: 0;
          z-index: 9;
          border-radius: 9999px;

          font-weight: bold;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}