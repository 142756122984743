.specialities {
    margin: 60px 0;

    .speciality {
        &-logo {
            height: 40px;
        }

        h3 {
            margin: 30px 0 15px 0;
        }

        p {
            margin: 0;
        }
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
    .specialities {
        margin: 10px 0;

        .speciality {
            &-logo {
                height: 30px;
            }

            h3 {
                margin: 15px 0 15px 0;
            }

            p {
                margin: 0;
            }
        }
    }
}