/*========================================================
=                       Typography                        =
=========================================================*/

$helvetica: 'Helvetica LT Std', sans-serif;
$din-condensed: "din-condensed", sans-serif;
$futura: "futura-pt", sans-serif;

* {
  font-family: $helvetica;
  font-weight: 400;
  font-style: normal;
}

h1.display {
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: $black;
  font-family: $din-condensed
}

h1,
.uk-h1 {
  font-size: 40px;
  line-height: 48px;
  color: $black;
  font-family: $din-condensed
}

h2,
.uk-h2 {
  font-size: 32px;
  line-height: 38.4px;
  color: $black;
  font-family: $din-condensed
}

h3,
.uk-h3 {
  font-size: 24px;
  line-height: 28.8px;
  color: $black;
  font-family: $din-condensed
}

h4,
.uk-h4 {
  font-size: 18px;
  line-height: 27px;
  color: $black;
  font-family: $din-condensed
}

a {
  color: $primary;

  &:hover {
    color: darken($color: $primary, $amount: 8%);
  }
}

.blog-categories {
  font-size: 14px;
  color: $muted;
  line-height: 21px;
  font-family: $helvetica;
  text-transform: uppercase;
  width: fit-content;
}

p,
.cms,
.text {
  font-size: 16px;
  line-height: 24px;
  color: $black;

  em {
    color: $text;
  }

  a {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: darken($color: $primary, $amount: 8%);
    }
  }

  table {
    border: 1px solid $black;
    border-collapse: collapse;

    th,
    td {
      padding: 8px 15px;
    }
  }

  // size
  &--big {
    font-size: 18px;
    line-height: 27px;

    p {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &--small {
    font-size: 14px;
    line-height: 21px;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &--tiny {
    font-size: 11px;

    p {
      font-size: 11px;
    }
  }

  // color
  &--white {
    color: $white;

    p {
      color: $white;
    }
  }

  &--grey {
    color: $grey;

    p {
      color: $grey;
    }
  }

  &--grey-dark {
    color: $grey-dark;

    p {
      color: $grey-dark;
    }
  }

  &--black {
    color: $black;

    p {
      color: $black;
    }
  }

  &--primary {
    color: $primary;

    p {
      color: $primary;
    }
  }

  &--success {
    color: $success-color;

    p {
      color: $success-color;
    }
  }

  &--warning {
    color: $warning-color;

    p {
      color: $warning-color;
    }
  }

  &--danger {
    color: $danger-color;

    p {
      color: $danger-color;
    }
  }

  // alingment
  &--center {
    text-align: center;

    h1,
    .uk-h1,
    h2,
    .uk-h2,
    h3,
    .uk-h3,
    h4,
    .uk-h4,
    h5,
    .uk-h5,
    p,
    .cms,
    .text {
      text-align: center;
    }
  }

  &--right {
    text-align: right;

    h1,
    .uk-h1,
    h2,
    .uk-h2,
    h3,
    .uk-h3,
    h4,
    .uk-h4,
    h5,
    .uk-h5,
    p,
    .cms,
    .text {
      text-align: right;
    }
  }
}

p {
  margin-bottom: 0;

  &+p {
    margin-top: 24px;
  }
}

.no-hover {
  &:hover {
    text-decoration: none;
  }
}

.input-errors {
  p {
    color: $danger-color;
    font-size: 14px;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

sub,
sup {
  font-size: 100%;
}

address {
  font-size: 16px;
  line-height: 24px;
  color: $text;

  a {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: darken($primary, 8%);
    }
  }
}

// general lists
ol,
ul {
  padding-left: 20px;

  li {
    font-size: 16px;
    line-height: 24px;
    color: $text;

    &::marker {
      color: $primary;
      font-size: 20px;
    }
  }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  h1.display {
    font-weight: normal;
    font-size: 32px;
    line-height: 38.4px;
    color: $black;
    font-family: $din-condensed
  }

  h1,
  .uk-h1 {
    font-size: 24px;
    line-height: 28.8px;
    color: $black;
    font-family: $din-condensed
  }

  h2,
  .uk-h2 {
    font-size: 24px;
    line-height: 28.8px;
    color: $black;
    font-family: $din-condensed
  }

  h3,
  .uk-h3 {
    font-size: 18px;
    line-height: 21.6px;
    color: $black;
    font-family: $din-condensed
  }

  h4,
  .uk-h4 {
    font-size: 18px;
    line-height: 27px;
    color: $black;
    font-family: $din-condensed
  }

  p,
  a {
    font-size: 14px;
  }

  .p--small,
  .cms--small,
  .text--small {
    font-size: 12px;
    line-height: 18px;
  }

  .blog-categories {
    font-size: 12px;
    line-height: 18px;
  }
}